import { RefundStatus } from './charge'
import { PassType, StatusTeslaLink } from './pass'

export enum OrderState {
    PROCESSED = 'PROCESSED',
    PROCESSING = 'PROCESSING'
}

export interface OrganizationFilter {
    searchField: string
    hasAllOrganization: boolean
    pages: {
        index: number
        perPage: number
        totalItems: number
        disabled: boolean
    }
}

export interface PayloadFilters {
    pages: {
        index: number
        perPage: number
    }
    keyword?: string
    state?: OrderState | null
}

interface OptionFilter {
    id: number
    name: string | null
    value: string | null
}

export interface Filters {
    id: number
    name: string
    options: OptionFilter[]
    value: string | null | boolean
}

export interface ChargeSessionFilter {
    index: number
    perPage: number
    search: string
    isSuccess: boolean | null
    dateRange: (string | null)[]
}

export interface BillAndExpenseReportsFilter {
    pages: {
        index: number
        perPage: number
    }
    dateRange: string[]
    orderType: string
    orderColumn: string
    keyword?: string
    state?: string
}

export interface CallForFundFilter {
    pages: {
        index: number
        perPage: number
    }
    minStartDate?: string
    maxStartDate?: string
    keyword?: string
    state?: string
}

export interface ChargeFilter {
    pages: {
        index: number
        perPage: number
        totalItems: number
    }
    searchField?: string
    isSuccess?: boolean | null
    dateRange?: (string | undefined)[]
    hasInvoice?: boolean | null
    isAccepted?: boolean | null
    isKnown?: boolean | null
    billed?: boolean | null
    refundStatus?: RefundStatus[]
}

export interface FilterList {
    searchField: string
    email?: string
    pages: {
        index: number
        perPage: number
    }
    hasRefundBankAccountStatus?: boolean
    orderBy?: string
    hasBusinessPass?: boolean | null
    hasDomesticZone?: boolean | null
    dateRange?: string[]
}

export interface FilterPass {
    searchField: string
    lost?: boolean | null
    type?: PassType
    isAssigned?: boolean | null
    hasLimit?: boolean | null
    isActivated?: boolean | null
    pages: {
        index: number
        perPage: number
        totalItems?: number
    }
}

export interface FilterTeslaPass {
    searchField: string
    teslaLinkStatus?: StatusTeslaLink | null
    pages: {
        index: number
        perPage: number
        totalItems?: number
    }
}

export interface BusinessFilter {
    searchField: string
    hasAllBusinessUnit: boolean
    pages: {
        index: number
        perPage: number
        totalItems: number
        disabled: boolean
    }
}
