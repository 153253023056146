import { OverstayParkingTariff } from '../interfaces/chargebox'
import { LicenseType } from '../interfaces/license'
import { Chargebox } from '~/interfaces/chargebox'
import { TypeZone } from '~/interfaces/zone'
import { axiosWrapper } from '~/utilitary/storeWrapperVuex'

const state = () => ({
    chargeboxesAvailable: [] as Chargebox[],
    chargebox: null as Chargebox | null
})

const getters = {
    getChargebox(state: any) {
        return state.chargebox
    },
    getChargeboxesAvailable(state: any) {
        return state.chargeboxesAvailable
    }
}

const mutations = {
    setChargebox(state: any, chargebox: Chargebox | null) {
        state.chargebox = chargebox
    },
    setChargeboxesAvailable(state: any, chargeboxesAvailable: Chargebox[]) {
        state.chargeboxesAvailable = chargeboxesAvailable
    }
}
const actions = {
    updateChargebox(
        _: any,
        payload: {
            idBU: number
            idChargebox: number
            name: string
            idZone: number
        }
    ) {
        const { idChargebox, idZone, idBU } = payload
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(
                    `/supervisionapi/business-units/${idBU}/zones/${idZone}/chargeboxes`,
                    {
                        name: payload.name,
                        chargebox_id: idChargebox
                    }
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchById(_: any, payload: { idBU: number; id: number }) {
        const { idBU, id } = payload
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(
                    `/supervisionapi/business-units/${idBU}/chargeboxes/${id}`
                )
                .then((res: any) => {
                    const chargebox = (this as any).$toCamel(res)
                    resolve(chargebox)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    fetchChargeboxesAvailable(
        this: any,
        context: any,
        payload: {
            idBU: string
            filters: {
                pages: {
                    index: number
                    perPage: number
                }
            }
        }
    ) {
        const { idBU, filters } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        return this.$axios
            .get(`/supervisionapi/business-units/${idBU}/chargeboxes`, {
                params: { activated: false, offset, limit }
            })
            .then((res: any) => {
                const chargeboxes = (this as any).$toCamel(res.data)
                context.commit('setChargeboxesAvailable', chargeboxes.items)
                const count = parseInt(res.headers['x-total-count'], 10)
                return {
                    items: chargeboxes.items,
                    count
                }
            })
            .catch((e: any) => {
                throw e
            })
    },
    fetchAuthorizations(_: any, payload: { id: number; idBU: number }) {
        const { idBU, id } = payload
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(
                    `/supervisionapi/business-units/${idBU}/chargeboxes/${id}/rules`
                )
                .then((res: any) => {
                    const authorizations = (this as any).$toCamel(res)
                    resolve(authorizations)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    updateAuthorizations(
        _: any,
        payload: { idBU: number; chargeboxId: number; authorizations: string }
    ) {
        const { idBU } = payload
        return new Promise((resolve, reject) => {
            const authorizationType = { type: payload.authorizations }
            ;(this as any).$axios
                .$put(
                    `/supervisionapi/business-units/${idBU}/chargeboxes/${payload.chargeboxId}/rules`,
                    authorizationType
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    addLocalPass(
        _: any,
        payload: {
            idOrga: number
            idBU: number
            chargeboxId: number
            passId: number
        }
    ) {
        const { idBU, chargeboxId } = payload
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(
                    `/supervisionapi/business-units/${idBU}/chargeboxes/${chargeboxId}/rules/passes`,
                    {
                        pass_id: payload.passId,
                        organization_id: payload.idOrga
                    }
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    removeLocalPass(
        _: any,
        payload: { idBU: number; chargeboxId: number; passId: number }
    ) {
        const { idBU, chargeboxId, passId } = payload
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$delete(
                    `/supervisionapi/business-units/${idBU}/chargeboxes/${chargeboxId}/rules/passes/${passId}`
                )
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    },
    activate(
        this: any,
        _: any,
        payload: {
            idBU: number
            idChargebox: number
            chargeboxName?: string
            simCardRequired: boolean
            licenseRebilling: boolean
            zoneType: TypeZone
        }
    ) {
        const {
            idBU,
            idChargebox,
            chargeboxName,
            simCardRequired,
            licenseRebilling,
            zoneType
        } = payload

        let chargeboxLicenseType = licenseRebilling
            ? 'CHARGEBOX_REBILLING'
            : 'CHARGEBOX'
        if (
            zoneType === TypeZone.DOMESTIC ||
            zoneType === TypeZone.CONNECTED_CABLE
        )
            chargeboxLicenseType = 'CHARGEBOX_DOMESTIC'

        const params = {
            activated: true,
            sim_card_required: simCardRequired,
            name: chargeboxName || undefined,
            chargebox_license_type: chargeboxLicenseType
        }

        return this.$axios
            .$patch(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}`,
                params
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    deactivate(
        this: any,
        _: any,
        payload: { idBU: number; idChargebox: number }
    ) {
        const { idBU, idChargebox } = payload
        const params = {
            activated: false
        }

        return this.$axios
            .$patch(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}`,
                params
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    cancelLicense(
        this: any,
        _: any,
        payload: { idBU: number; idChargebox: number }
    ) {
        const { idBU, idChargebox } = payload
        const params = {
            renewable: false
        }

        return this.$axios
            .$patch(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}`,
                params
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    renewLicense(
        this: any,
        _: any,
        payload: { idBU: number; idChargebox: number }
    ) {
        const { idBU, idChargebox } = payload
        const params = {
            renewable: true
        }

        return this.$axios
            .$patch(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}`,
                params
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    upgradeLicense(
        this: any,
        _: any,
        payload: { idBU: number; idChargebox: number }
    ) {
        const { idBU, idChargebox } = payload
        const params = {
            license_type: LicenseType.CHARGEBOX_REBILLING
        }

        return this.$axios
            .$post(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}/upgrade`,
                params
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    delete(this: any, _: any, idChargebox: number) {
        return this.$axios
            .$delete(`/supervisionapi/chargeboxes/${idChargebox}`)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    },
    fetchAdmin(
        this: any,
        _: any,
        payload: {
            activated: boolean
            keyword: string
            filters: {
                pages: {
                    index: number
                    perPage: number
                }
            }
        }
    ) {
        const { filters, keyword, activated } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)

        return this.$axios
            .get('/supervisionapi/chargeboxes', {
                params: { keyword, offset, limit, activated }
            })
            .then((res: any) => {
                const resDTO = res.data.items
                const count = parseInt(res.headers['x-total-count'], 10)
                const items = (this as any).$toCamel(resDTO) as Chargebox[]
                return {
                    count,
                    items
                }
            })
            .catch((e: any) => {
                throw e
            })
    },
    renameChargebox(
        this: any,
        _: any,
        payload: { idBU: number; idChargebox: number; name: string }
    ) {
        const { idBU, idChargebox, name } = payload

        return this.$axios
            .$patch(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}`,
                { name }
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    updateDisplayIdentity(
        this: any,
        _: any,
        payload: { idChargebox: number; displayIdentity: string }
    ) {
        const { idChargebox, displayIdentity } = payload

        return this.$axios
            .$patch(`/supervisionapi/chargeboxes/${idChargebox}`, {
                display_identity: displayIdentity
            })
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    importIdentities(
        this: any,
        _: any,
        payload: {
            attachment: any
            shouldUpdate: boolean
        }
    ) {
        const { attachment, shouldUpdate } = payload
        const formData = new FormData()
        formData.append('identities', attachment)

        let url = `/supervisionapi/identities/batch`
        if (shouldUpdate) {
            url += `?should_update=${shouldUpdate}`
        }

        return new Promise((resolve, reject) => {
            axiosWrapper
                .bind(this)('post', url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res: { imported: number; ignored: number }) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    fetchSuggestedZone(
        this: any,
        _: any,
        payload: { idBU: number; idChargebox: number }
    ) {
        const { idBU, idChargebox } = payload
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(
                    `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}/suggested-zone`
                )
                .then((res: any) => {
                    resolve(this.$toCamel(res))
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    transferChargeboxToAnotherBU(
        this: any,
        _: any,
        payload: { idChargebox: number; idBUToTransferTo: number }
    ) {
        const { idChargebox, idBUToTransferTo } = payload
        const params = {
            business_unit_id: idBUToTransferTo
        }

        return this.$axios.$patch(
            `/supervisionapi/chargeboxes/${idChargebox}`,
            params
        )
    },
    activateChargeboxOverstayParkingFeesOption(
        this: any,
        _: any,
        payload: { idBU: number; idChargebox: number }
    ) {
        const { idBU, idChargebox } = payload

        return this.$axios
            .$patch(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}`,
                {
                    overstay_parking_tariff_activated: true
                }
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    updateChargeboxOverstayParkingTariff(
        this: any,
        _: any,
        payload: {
            idBU: number
            idChargebox: number
            overstayParkingTariff: OverstayParkingTariff
        }
    ) {
        const { idBU, idChargebox, overstayParkingTariff } = payload

        return this.$axios
            .$post(
                `/supervisionapi/business-units/${idBU}/chargeboxes/${idChargebox}/overstay-parking-tariffs`,
                (this as any).$toSnake(overstayParkingTariff)
            )
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    },
    batchChargeboxesOverstayParkingLicenses(
        this: any,
        _: any,
        payload: {
            idBU: number
            idChargeboxes: number[]
        }
    ) {
        const { idBU, idChargeboxes } = payload

        return this.$axios
            .$put(`/supervisionapi/business-units/${idBU}/licenses/batch`, {
                license_type: LicenseType.OVERSTAY_PARKING_FEES,
                chargebox_ids: idChargeboxes
            })
            .then((res: any) => this.$toCamel(res))
            .catch((e: any) => {
                throw e
            })
    }
}

export default {
    namespaced: true as true,
    state,
    getters,
    mutations,
    actions
}
